import { bridgeState } from './bridge-state';

export function log(message: string, object?: any) {
  if (bridgeState.debugModeEnabled) {
    console.log(`[SRFBridge] ${message} ${stringify(object)}`);
  }
}

function stringify(object?: any) {
  if (object) {
    try {
      return JSON.stringify(object);
    } catch {
      // ignore errors
    }
  }
  return '';
}
