import { notifyConnectListeners, notifyEventListeners } from './event-listener';
import { sendAllQueuedEvents } from './event-sender';
import { OutgoingEvent } from './event-types';

export const SRFLegacyBridge = {
  init,
  isReady,
  isRunningInLegacyApp,
  sendEvent,
  isAutoplayEnabled,
};

/**
 * @returns true if we are running in a legacy app.
 */
function isRunningInLegacyApp() {
  return typeof window.nativebridge !== 'undefined';
}

/**
 * @returns true if a legacy app is connected and is ready to receive events.
 */
function isReady() {
  return isRunningInLegacyApp() && window.legacyAppReady === true;
}

function init() {
  waitForLegacyApp(() => {
    sendAllQueuedEvents();
    notifyConnectListeners();
    checkAutoplayPeriodically();
    sendDeviceInfo();
  });
}

function sendEvent(event: OutgoingEvent) {
  switch (event.eventType) {
    case 'PageMetaData':
      window.nativebridge?.onMetadataRetrieved(JSON.stringify(event.payload));
      break;
    case 'TrackingEvent':
      window.nativebridge?.trackEvent(JSON.stringify(event.payload));
      break;
    case 'PlayVideo':
    case 'PlayAudio':
    case 'PlayLivestream':
    case 'OpenArticle':
    case 'OpenLandingPage':
    case 'OpenLink':
      window.open(event.payload.url);
      break;
  }
}

function isAutoplayEnabled(): boolean {
  return window.legacyAutoplayEnabled ?? false;
}

function waitForLegacyApp(callback: () => void) {
  if (isReady()) {
    callback();
    return;
  }

  // clearInterval seems not always to work correctly on some iOS
  // devices. We therefore keep track of it ourselves, to only call the
  // callback function once.
  let intervalCleared = false;
  const waitDelay = 500;
  let waitCount = 30;

  try {
    const intervalID = window.setInterval(() => {
      waitCount--;
      if (isReady() && !intervalCleared) {
        window.clearInterval(intervalID);
        intervalCleared = true;
        callback();
      } else if (waitCount === 0) {
        // No legacy app connected after 1s => we assume it won't happen and stop waiting.
        window.clearInterval(intervalID);
      }
    }, waitDelay);
  } catch (e) {
    // Ignore
  }
}

// Legacy iOS apps return a promise when nativebridge.isAutoplayEnabled() is called.
// To make it easier for library users, we check this setting periodically and
// return the current value of it in isAutoplayEnabled() directly.
function checkAutoplayPeriodically() {
  if (window.nativebridge?.isAutoplayEnabled) {
    updateAutoplaySetting();
    window.setInterval(updateAutoplaySetting, 5000);
  }
}

function updateAutoplaySetting() {
  const promise = window.nativebridge?.isAutoplayEnabled?.();
  promise?.then(isEnabled => (window.legacyAutoplayEnabled = isEnabled));
}

// After calling fetchMetaData(), the legacy apps will set the device info.
// We convert this data to an OutgoingEvent and send it to the registered listeners.
function sendDeviceInfo() {
  if (window.legacyDeviceInfo) {
    notifyEventListeners({
      eventType: 'DeviceInfo',
      payload: {
        deviceId: window.legacyDeviceInfo.ceid,
        siteName: window.legacyDeviceInfo.navigation_app_site_name,
      },
    });
  } else {
    setTimeout(sendDeviceInfo, 100);
  }
}
