import { bridgeState } from './bridge-state';
import { OutgoingEvent } from './event-types';
import { SRFLegacyBridge } from './legacy-compatibility';
import { log } from './logger';

let eventQueue: OutgoingEvent[] = [];

export function sendEvent(event: OutgoingEvent) {
  if (bridgeState.nativePort) {
    sendEventToApp(event);
  } else if (SRFLegacyBridge.isReady()) {
    SRFLegacyBridge.sendEvent(event);
  } else {
    eventQueue.push(event);
  }
}

export function sendAllQueuedEvents() {
  eventQueue.forEach(event => sendEvent(event));
  eventQueue = [];
}

function sendEventToApp(event: OutgoingEvent) {
  try {
    log('Sending', event);
    bridgeState.nativePort?.postMessage(JSON.stringify(event));
  } catch (e) {
    log('Failed to send', e);
  }
}
