import { bridgeState } from './bridge-state';
import { sendAllQueuedEvents } from './event-sender';
import { IncomingEvent } from './event-types';
import { SRFLegacyBridge } from './legacy-compatibility';
import { log } from './logger';

export function handleEvent(rawEvent: any) {
  const event = parseJSON(rawEvent.data) as IncomingEvent;

  if (event.eventType) {
    log('Received', event);

    switch (event.eventType) {
      case 'Initialize':
        bridgeState.autoplayEnabled = event.payload.autoplay ?? false;

        // Android passes the port when sending the InitializeEvent
        // iOS defines the port globally on the webkit object
        bridgeState.nativePort =
          rawEvent.ports?.[0] || window.webkit?.messageHandlers?.nativePort;

        bridgeState.debugModeEnabled =
          bridgeState.debugModeEnabled || event.payload.debugMode || false;

        if (event.payload.deviceId) {
          notifyEventListeners({
            eventType: 'DeviceInfo',
            payload: {
              deviceId: event.payload.deviceId,
              siteName: event.payload.appId,
            },
          });
        }

        sendAllQueuedEvents();
        notifyConnectListeners();
        break;
      case 'SetAutoplay':
        bridgeState.autoplayEnabled = event.payload.autoplay ?? false;
        break;
      case 'SetViewMode':
        bridgeState.compactViewModeEnabled = event.payload.isCompact ?? false;
        break;
    }

    notifyEventListeners(event);
  }
}

function parseJSON(data: any) {
  try {
    return JSON.parse(data) ?? {};
  } catch (e) {
    // ignore parsing errors and return an empty object
    return {};
  }
}

let isRegistered: boolean = false;

export function registerEventHandler() {
  if (!isRegistered) {
    window.addEventListener('message', handleEvent);
    isRegistered = true;
  }
}

export type BridgeEventListener = (event: IncomingEvent) => any;

const listeners: { [key: string]: BridgeEventListener[] } = {};

export function addListener(eventType: string, listener: BridgeEventListener) {
  if (!listeners[eventType]) {
    listeners[eventType] = [];
  }
  listeners[eventType].push(listener);
}

export function notifyEventListeners(event: IncomingEvent) {
  const listenersToNotify = listeners[event.eventType] ?? [];
  listenersToNotify.forEach(listener => listener(event));
}

export type ConnectListener = () => any;

let connectListeners: ConnectListener[] = [];

export function addConnectListener(listener: ConnectListener) {
  connectListeners.push(listener);
  notifyConnectListeners();
}

export function notifyConnectListeners() {
  if (bridgeState.nativePort || SRFLegacyBridge.isReady()) {
    connectListeners.forEach(listener => {
      try {
        listener();
      } catch {
        // ignore errors in connect listeners
      }
    });
    connectListeners = [];
  }
}
