type BridgeState = {
  nativePort?: MessagePort;
  autoplayEnabled: boolean;
  debugModeEnabled: boolean;
  compactViewModeEnabled: boolean;
};

export const bridgeState: BridgeState = {
  autoplayEnabled: false,
  debugModeEnabled: false,
  compactViewModeEnabled: false,
};
